<template>
    <div>
        <b-button class="mb-2 mr-sm-2 btn-responsive float-left" size="sm" variant="primary" @click="toggleSelectAllRows()" v-if="true">
            {{ allSelected ? "Avmarkera alla rader" : "Markera alla rader" }}
        </b-button>
        <b-button
            class="mb-2 mr-sm-2 btn-responsive float-left"
            size="sm"
            variant="danger"
            :disabled="!this.$store.getters.getProgramsHaveSelectedRow"
            @click="excludeSelected(), (allSelected = true), toggleSelectAllRows()"
            v-if="true"
        >
            Exkludera rader
        </b-button>
        <b-button
            class="mb-2 mr-sm-2 btn-responsive float-left"
            size="sm"
            variant="primary"
            @click="$emit('onSumButtonClicked')"
            :disabled="!this.$store.getters.getProgramsHaveSelectedRow"
            v-if="config_ShowSummaryProgram"
        >
            Ta markeringar till Summering
        </b-button>
        <label class="totalRowsStyle float-left"> Svarsrader: {{ getRowCount }} </label>
        <b-table
            thead-class="stickyfancytableheader"
            :fields="fields"
            class="tablemainresultclass"
            :per-page="visibleRows"
            :current-page="currentPage"
            select-mode="multi"
            :items="levelOneProgramsWithLevelTwoPrograms"
            bordered
            small
            primary-key="index"
            striped
            :sort-compare-options="{ numeric: true, sensitivity: 'base' }"
            sort-compare-locale="sv"
            :head-variant="'dark'"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @row-selected="rowSelected"
            @sort-changed="onSort"
        >
            <template v-slot:cell(plussign)="row">
                <div class="plusminuscontainer" v-if="row.item.numberOfLvl2Objects > 1">
                    <div @click="clickedPlusMinusSign($event, row)" class="plusminusbutton">
                        <font-awesome-icon v-if="!row.item._showDetails" icon="fa fa-plus-circle" class="plusminusicon" />
                        <font-awesome-icon v-else icon="fa fa-minus-circle fa-1x" class="plusminusicon" />
                        <div class="plusminusline"></div>
                    </div>
                </div>
                <div v-else-if="row.item.numberOfLvl2Objects === 1">
                    {{ setLevelOneProgramToLevelTwoProgram(row.item) }}
                </div>
            </template>
            <template v-slot:cell(chosen)="row">
                <b-form-checkbox
                    class="choose-checkbox-div"
                    v-model="row.item.chosen"
                    @change="onLvlOneCheckClick(row.item), checkSelection()"
                    :indeterminate.sync="row.item.indeterminate"
                ></b-form-checkbox>
            </template>
            <template v-slot:head()="field">
                <span class="measureheader" v-html="getTableFieldLabel(field)"></span>
            </template>
            <template v-slot:cell(providerName)="row">
                <span v-if="row.item.providerNameVhtml != undefined" v-html="row.item.providerNameVhtml"></span>
                <span v-else>{{ row.item.providerName }}</span>
            </template>
            <template #row-details="row">
                <b-table-lite
                    class="mb-0 detail-row-table"
                    :class="groupClassOddEven(row.index)"
                    :tbody-tr-class="detailRowClassOddEven(row.index)"
                    borderless
                    thead-class="hideheader"
                    :fields="fields"
                    :items="row.item.levelTwoPrograms"
                    :striped="false"
                >
                    <template v-slot:cell(chosen)="detailRow">
                        <b-form-checkbox class="choose-checkbox-div" v-model="detailRow.item.chosen" @change="checkSelection(), anyLevelTwoSelected(row)"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(providerName)="detailRow">
                        <span v-if="detailRow.item.providerNameVhtml != undefined" v-html="detailRow.item.providerNameVhtml"></span>
                        <span v-else>{{ detailRow.item.providerName }}</span>
                    </template>
                </b-table-lite>
            </template>
        </b-table>
        <b-pagination
            class="justify-content-center"
            v-model="currentPage"
            :total-rows="$store.getters.getProgramsResults.data.levelOneProgramsWithLevelTwoPrograms.length"
            :per-page="visibleRows"
            aria-controls="my-table"
        ></b-pagination>
    </div>
</template>
<script>
import globalConfig from "@/mixins/globalConfig"
import * as Mutations from "@/store/modules/programs/mutation-types"
export default {
    name: "ResultTableMainProgram",
    props: ["sortBy", "sortDesc"],
    mixins: [globalConfig],
    data() {
        return {
            allSelected: false,
            selectClickedRow: true,
            visibleRows: 50,
            callOnce: false,
            currentPage: 1,
            selection: [],
        }
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
    },
    computed: {
        getRowCount() {
            let totalLength = this.$store.getters.getProgramsResults.data.levelOneProgramsWithLevelTwoPrograms.length
            let previousRowsAndCurrentRows = this.visibleRows * this.currentPage
            if (previousRowsAndCurrentRows > totalLength) {
                return `${totalLength}/${totalLength}`
            }
            return `${previousRowsAndCurrentRows}/${totalLength}`
        },
        visibleRowsStartIndex() {
            if (this.visibleRows > this.$store.getters.getProgramsResults.data.levelOneProgramsWithLevelTwoPrograms.length) {
                return 0
            }
            return this.visibleRows * (this.currentPage - 1)
        },
        visibleRowsEndIndex() {
            let totalLength = this.$store.getters.getProgramsResults.data.levelOneProgramsWithLevelTwoPrograms.length
            if (this.visibleRows > totalLength || this.visibleRows * (this.currentPage - 1) + this.visibleRows > totalLength) {
                return this.$store.getters.getProgramsResults.data.levelOneProgramsWithLevelTwoPrograms.length
            }
            return this.visibleRows * (this.currentPage - 1) + this.visibleRows
        },
        levelOneProgramsWithLevelTwoPrograms() {
            return this.$store.getters.getProgramsResults.data.levelOneProgramsWithLevelTwoPrograms
        },
        fields() {
            let fields = [...this.$store.getters.getProgramsResults.data.columns]
            fields.unshift({ key: "plussign", tdClass: "plusminussigntd", thClass: "plusminussignth", label: "" }, { key: "chosen", thClass: "choose-checkbox-th", label: "Valda" })
            for (const field of fields) {
                if (field.key === "title") {
                    field.thClass = "titleheadercolumn"
                } else if (field.key === "date") {
                    field.thClass = "date-th"
                } else if (field.key.includes("measure")) {
                    field.sortDirection = "desc"
                }
            }
            return fields
        },
    },
    methods: {
        onSort(sortInfo) {
            this.$emit("update:sortBy", sortInfo.sortBy)
            this.$emit("update:sortDesc", sortInfo.sortDesc)
        },
        rowSelected(row) {
            if (row.length === 1) {
                row[0].chosen = true
                this.onLvlOneCheckClick(row[0])

                this.levelOneProgramsWithLevelTwoPrograms.forEach(element => {
                    if (row[0].index != element.index) {
                        element.chosen = false
                        if (element.levelTwoPrograms) {
                            element.levelTwoPrograms.map(c => (c.chosen = false))
                        }
                    }
                })
            }
            if (row.length > 1) {
                this.levelOneProgramsWithLevelTwoPrograms.forEach(e => {
                    row.forEach(element => {
                        if (element.index == e.index) {
                            e.chosen = true
                            if (e.levelTwoPrograms) {
                                e.levelTwoPrograms.map(c => (c.chosen = true))
                            }
                        }
                    })
                })
            }

            this.checkSelection()
        },
        setLevelOneProgramToLevelTwoProgram(row) {
            for (const column of this.$store.getters.getProgramsResults.data.columns) {
                if (column.key === "providerName" && row.levelTwoPrograms[0].providerNameVhtml != undefined) {
                    row.providerNameVhtml = row.levelTwoPrograms[0].providerNameVhtml
                } else {
                    row[column.key] = row.levelTwoPrograms[0][column.key]
                }
            }
        },
        updateLevelOneProgramFromLevelTwoPrograms(l1) {
            for (const column of this.$store.getters.getProgramsResults.data.columns) {
                let l2Values = new Set()
                for (const l2 of l1.levelTwoPrograms) {
                    l2Values.add(l2[column.key])
                }
                if (column.key == "providerName") {
                    l1[column.key] = [...l2Values].toString()
                } else if (l2Values.size == 1) {
                    l1[column.key] = [...l2Values].toString()
                }
            }
        },
        onLvlOneCheckClick(row) {
            for (const l2 of row.levelTwoPrograms) {
                l2.chosen = row.chosen
            }
        },
        checkSelection() {
            let selectCount = 0
            let rowCount = 0
            for (const l1 of this.levelOneProgramsWithLevelTwoPrograms) {
                for (const l2 of l1.levelTwoPrograms) {
                    if (l2.chosen == true) {
                        selectCount++
                    }
                    rowCount++
                }
            }
            this.allSelected = selectCount >= rowCount
            const hasSelection = selectCount > 0
            this.$store.commit(Mutations.setProgramsHaveSelectedRow, hasSelection)
        },
        clickedPlusMinusSign(event, row) {
            event.stopPropagation()
            this.$set(row.item, "_showDetails", !row.item._showDetails)
            this.selectClickedRow = false
        },
        detailRowClassOddEven(index) {
            return index % 2 == 0 ? "row-even detail-row" : "row-odd detail-row"
        },
        groupClassOddEven(index) {
            return index % 2 == 0 ? "group-even" : "group-odd"
        },
        getTableFieldLabel(field) {
            if (!field.field.key.includes("measure")) {
                return field.field.label
            }
            return `${field.field.header} <span class="measureagewrapper">${field.field.subHeader}</span>`
        },
        excludeSelected() {
            let remainingResults = this.levelOneProgramsWithLevelTwoPrograms.reduce((result, l1) => {
                l1.chosen = false
                const prevL2sLength = l1.levelTwoPrograms.length
                l1.levelTwoPrograms = l1.levelTwoPrograms.filter(l2 => {
                    //set chosen to false for all l2s so UI is updated, to avoid timing issues on large sets
                    const isChosen = l2.chosen
                    l2.chosen = false
                    return !isChosen
                })
                if (!l1.levelTwoPrograms?.length) {
                    return result
                } else if (prevL2sLength === l1.levelTwoPrograms.length) {
                    result.push(l1)
                    return result
                } else if (l1.levelTwoPrograms.length === 1) {
                    l1._showDetails = false
                    l1.numberOfLvl2Objects = l1.levelTwoPrograms.length
                    result.push(l1)
                    return result
                } else {
                    this.updateLevelOneProgramFromLevelTwoPrograms(l1)
                    l1.numberOfLvl2Objects = l1.levelTwoPrograms.length
                    result.push(l1)
                    return result
                }
            }, [])
            const updatedResults = {
                ...this.$store.getters.getProgramsResults,
                data: {
                    ...this.$store.getters.getProgramsResults.data,
                    levelOneProgramsWithLevelTwoPrograms: remainingResults,
                },
            }
            this.$store.commit(Mutations.setProgramsResults, updatedResults)
        },
        toggleSelectAllRows() {
            this.allSelected = !this.allSelected
            for (const l1 of this.levelOneProgramsWithLevelTwoPrograms) {
                l1.indeterminate = false
            }
            //checkbox indeterminate status is lagging, wait to set chosen status
            this.$nextTick(() => {
                for (const l1 of this.levelOneProgramsWithLevelTwoPrograms) {
                    l1.chosen = this.allSelected
                    if (l1.numberOfLvl2Objects > 0) {
                        for (const l2 of l1.levelTwoPrograms) {
                            l2.chosen = this.allSelected
                        }
                    }
                }
            })
            this.$store.commit(Mutations.setProgramsHaveSelectedRow, this.allSelected)
        },
        anyLevelTwoSelected(row) {
            row.item.indeterminate = false
            row.item.chosen = false
            let chosen = 0
            for (const l2 of row.item.levelTwoPrograms) {
                if (l2.chosen) {
                    chosen++
                }
            }
            if (chosen === undefined || chosen == row.item.levelTwoPrograms.length || chosen == 0) {
                // not indeterminate
                this.$nextTick(() => {
                    row.item.indeterminate = false
                })
                if (chosen == row.item.levelTwoPrograms.length) {
                    row.item.chosen = true
                    this.$nextTick(() => {
                        row.item.indeterminate = false
                    })
                } else {
                    row.item.chosen = false
                    this.$nextTick(() => {
                        row.item.indeterminate = false
                    })
                }
            } else {
                //checkbox indeterminate status is lagging, wait
                this.$nextTick(() => {
                    row.item.indeterminate = true
                })
                row.item.indeterminate = true
            }
        },
    },
}
</script>
<style>
.plusminussignth {
    width: 2.2rem;
}
.table tbody tr .plusminussigntd {
    vertical-align: inherit;
    font-size: 0.9rem;
}
.choose-checkbox-th {
    width: 3rem;
}
</style>
<style scoped>
.choose-checkbox-div {
    padding-left: 2.15rem;
}
::v-deep .tablemainresultclass th {
    font-size: 0.8rem;
    background-color: #337ab7 !important;
    border-color: #bfd5e8 !important;
    border-top: white;
    border-width: 2px 1px 2px 1px !important;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
}

.resultgrey {
    background-color: #6c757d;
}
table.tablemainresultclass {
    text-align: left;
    min-width: 1200px;
    background-color: white;
    border-width: 0px 1px 1px 1px;
}
.plusminusicon {
    color: #337ab7;
    font-size: 1.25em;
    margin: auto;
    display: block;
}
::v-deep .detail-row {
    background-color: transparent !important;
}
::v-deep .detail-row td {
    font-size: 0.65rem;
}
/* table is using bootstrap striped attribute for main rows and index based css for detail rows*/
::v-deep .row-even {
    background-color: #ffffff;
}
::v-deep .row-odd {
    background-color: #f2f2f2;
}
::v-deep .group-even {
    background-color: #f2f2f2;
}
::v-deep .group-odd {
    background-color: #ffffff;
}
::v-deep tbody tr td {
    border-bottom: none;
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

::v-deep .titleheadercolumn {
    width: 20%;
}
::v-deep .plusminusbutton {
    display: flex;
    position: relative;
    z-index: 2;
    padding: 0.4em 0;
    margin: -0.4em 0;
    cursor: pointer;
}
/* hiding table group borders */
::v-deep .detail-row-table tbody tr td {
    border: none;
}
::v-deep .b-table-has-details td:not(:last-child) {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-right: 1px solid rgba(255, 255, 255, 0);
}

::v-deep .b-table-has-details td:first-child {
    border-left: 1px solid #dee2e6;
    border-right: 0px solid #dee2e6;
}
::v-deep .b-table-has-details td:nth-child(2) {
    border-left: 0px solid #dee2e6;
    border-right: 0px solid #dee2e6;
}
/* constructing the hierarchy lines in the plussign column */
::v-deep .plusminussigntd {
    padding: 0;
    position: relative;
}
/* hiding the overflowing line behind transparent part of +/- icon */
::v-deep .plusminusbutton::before {
    content: "";
    background: white;
    z-index: -1;
    height: 37%;
    left: 12px;
    position: absolute;
    top: 32%;
    width: 10px;
}
::v-deep .b-table-has-details td:first-child::after {
    content: "";
    background: #a9b1b8;
    height: 42%;
    left: 16px;
    position: absolute;
    top: 60%;
    width: 0.5px;
    z-index: 1;
}
::v-deep .detail-row-table .plusminussigntd::before {
    content: "";
    background: #a9b1b8;
    width: 50%;
    left: 16px;
    position: absolute;
    top: 50%;
    height: 0.5px;
}
::v-deep .detail-row-table .plusminussigntd:first-child::after {
    content: "";
    background: #a9b1b8;
    height: 100%;
    left: 16px;
    position: absolute;
    top: -50%;
    width: 0.5px;
}
</style>
