<template>
    <div>
        <b-dropdown toggle-class="buttonclass" class="w-100 column" variant="outline-secondary" :text="label">
            <b-form-group class="checkboxcolumn">
                <b-form-checkbox v-model="allIsSelected" @change="selectAll" class="mx-2">
                    {{ allIsSelected ? "Avmarkera alla" : "Välj Alla" }}
                </b-form-checkbox>
                <b-form-checkbox-group v-model="value" :options="fieldData.config.options" class="mx-2" @click.native="allIsSelected = false" stacked></b-form-checkbox-group>
            </b-form-group>
        </b-dropdown>
    </div>
</template>

<script>
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"

export default {
    name: "CheckboxDropdownColumns",
    mixins: [computedVal, props],
    data() {
        return {
            allIsSelected: true,
        }
    },
    computed: {
        label: function() {
            let NumberOf = this.getSelectedColumnsCount()

            if (NumberOf == 0) return this.fieldData.label
            return NumberOf != 1 ? this.fieldData.label + " (" + NumberOf + " valda)" : this.fieldData.label + " (" + NumberOf + " vald)"
        },
    },
    methods: {
        getSelectedColumnsCount() {
            let selected = this.$store.getters[this.getter]?.[this.fieldData.identifier] ? this.$store.getters[this.getter][this.fieldData.identifier] : []
            return selected.length
        },

        selectAll(checked) {
            let updatedQuery
            if (checked) {
                updatedQuery = {
                    ...this.$store.getters[this.getter],
                    [this.fieldData.identifier]: this.fieldData.config.options.map(opt => opt.value),
                }
            } else {
                let options = this.fieldData.config.options.filter(opt => {
                    if (opt.disabled) return opt
                })
                updatedQuery = {
                    ...this.$store.getters[this.getter],
                    [this.fieldData.identifier]: options.map(opt => opt.value),
                }
            }
            return this.$store.commit(this.setter, updatedQuery)
        },
    },
}
</script>
<style scoped>
.checkboxcolumn >>> .custom-checkbox {
    display: flex;
}
</style>
<style>
.checkboxcolumn > div > div > .custom-checkbox label,
.checkboxcolumn > div > div .custom-control-label {
    display: flex;
    align-items: center;
}
</style>
