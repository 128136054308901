export const baseFields = {
    start_date: {
        label: "Konsumtionsperiod från",
        errorMsg: "",
        type: "start_date_programs",
        identifier: "start_date",
        default: null,
        required: true,
    },
    end_date: {
        label: "Konsumtionsperiod till",
        errorMsg: "",
        type: "end_date_programs",
        identifier: "end_date",
        default: null,
        required: true,
    },
    pub_date: {
        identifier: "pub_date",
    },
    title: {
        label: "Titel",
        type: "text",
        identifier: "title",
        default: "",
    },
    providers: {
        label: "Kanaler",
        type: "provider-picker-programs",
        identifier: "provider_keys",
        title: "Kanaler",
        sites: [],
        channels: [],
        default: [],
    },
    genres: {
        label: "Genre",
        type: "genre-picker",
        identifier: "genres",
        config: {
            genres: [],
        },
        default: "",
    },
    target_group: {
        label: "Målgrupp",
        identifier: "target_group",
        type: "target_group_programs",
        config: {},
    },
    show_all_content_with_traffic_in_period: {
        label: "Visa tomma svar",
        active_text: "Visar tomma svar",
        inactive_text: "Döljer tomma svar",
        tooltip_text: "Visa allt innehåll med tittande under perioden (oavsett valda målgrupper)",
        identifier: "show_all_content_with_traffic_in_period",
        type: "toggle-button",
    },
}
