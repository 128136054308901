import dayjs from "dayjs"

const validationRules = component => {
    component.formErrors = []
    let query = component.$store.getters.getProgramsQuery
    let startDate = query.start_date
    let endDate = query.end_date
    let daysBetweenDates = dayjs(endDate).diff(startDate, "day") + 1
    let yearsBetweenDates = dayjs(endDate)
        .subtract(1, "d")
        .diff(startDate, "y")
    if (yearsBetweenDates >= 1) {
        component.formErrors.push("Max period är 1 år.")
    }
    let storedTargets = JSON.parse(localStorage.getItem("targetGroups"))
    storedTargets = !storedTargets || !storedTargets["getProgramsQuery"] ? [] : storedTargets["getProgramsQuery"]
    if (daysBetweenDates > 7) {
        let titleLength = 0
        let numberOfGenres = JSON.parse(localStorage.getItem("genres")) ? JSON.parse(localStorage.getItem("genres")).getProgramsQuery.length : 0
        if (query.title) titleLength = query.title.length
        let selectedChannels = localStorage.getItem("savedChannels") ? JSON.parse(localStorage.getItem("savedChannels")) : {}
        let channelCount = 0
        if (selectedChannels["getProgramsQuery"] !== undefined) {
            let channelhousesChannels = Object.values(selectedChannels["getProgramsQuery"])
            for (let i = 0; i < channelhousesChannels.length; i++) {
                channelCount += channelhousesChannels[i].length
            }
        }
        let countDays = countCertainDays(query.weekdays, new Date(startDate), new Date(endDate))
        if (titleLength < 2 && (numberOfGenres == 0 || numberOfGenres > 5) && (channelCount == 0 || channelCount > 10) && countDays > 7) {
            component.formErrors.push(
                "Det valda antalet veckodagar inom perioden överstiger 7 dagar. Var god ange antingen en titel med minst två bokstäver, använda 1-5 genres eller ange 1-10 kanaler för att begränsa din sökning."
            )
        }
        if (storedTargets.length > 6) component.formErrors.push("Max 6 mått får användas.")
        if (component.formErrors.length > 0) component.formErrors.unshift("Din sökning riskerar att returnera ett väldigt stort dataset.")
    }
}
function countCertainDays(days, d0, d1) {
    if (!days) return 0
    if (days.indexOf(7) !== -1) {
        days = days.filter(item => item !== 7)
        days.push(0)
    }
    var ndays = 1 + Math.round((d1 - d0) / (24 * 3600 * 1000))
    var sum = function(a, b) {
        return a + Math.floor((ndays + ((d0.getDay() + 6 - b) % 7)) / 7)
    }
    return days.reduce(sum, 0)
}
export { validationRules }
